export class TransferPlan {
  daysPinpad = null
  daysOnline = null
  anticipated = null
}

export class Account {
  active = null
  approved = null
  freeTrial = null
  balanceBlocked = null
  anticipatedTransfer = null
  oldAnticipationPlan = null
  comercialName = null
  softDescriptor = ''
  transferPlan = new TransferPlan()
}

export class Holder {
  id = ''
  fullName = ''
  birthDate = ''
  gender = ''
  phone = ''
  mobile = ''
  email = ''
  taxDocument = ''
  createdAt = ''
  company = null
  contact = new Contact()
}

export class BankAccount {
  accountNumber = null
  bankBranchNumber = null
  bankName = null
  bankNumber = null
  type = null
  variation = null
}

export class City {
  code = null
  name = null
  state = null
}

export class Address {
  city = new City()
  cityCode = null
  district = null
  line1 = null
  line2 = null
  streetNumber = null
  zipCode = null
}

export class Presets {
  address = new Address()
  bankAccount = new BankAccount()
  account = new Account()
  holder = new Holder()
}

export class Contact {
  address = null
  mobile = null
  phone = null
  phoneExtension = null
  email = null
  site = null
}

export class User {
  email = null
}

export class PresetParams {
  phone = null
  mobile = null
  commercialName = null
  softDescriptor = null
  transferPlan = new TransferPlan()
  bankAccount = new BankAccount()
  address = new Address()
  contact = new Contact()
  user = new User()

  constructor(preset, migrate) {
    this.phone = preset.holder.phone !== '' ? preset.holder.phone : '';
    this.mobile = preset.holder.mobile !== '' ? preset.holder.mobile : '';
    this.commercialName = preset.account.commercialName !== '' ? preset.account.commercialName : '';
    this.softDescriptor = preset.account.softDescriptor !== '' ? preset.account.softDescriptor : '';
    this.transferPlan = preset.account.transferPlan !== null ? preset.account.transferPlan : null;
    this.transferPlan.migrate = migrate !== null ? migrate : null;
    this.address = preset.address !== null ? preset.address : null;
    this.contact = preset.holder.contact !== null ? preset.holder.contact : null;
    this.contact.phoneExtension = this.contact.phoneExtension === '' ? null : this.contact.phoneExtension;
    this.user.email = preset.holder.email !== '' ? preset.holder.email : null;

    if (preset.bankAccount != null &&
      preset.bankAccount.accountNumber != null &&
      preset.bankAccount.bankBranchNumber != null) {
      this.bankAccount = preset.bankAccount;
    } else {
      this.bankAccount = null;
    }
  }
}
