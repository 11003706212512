import CustomApiError from './CustomError';

export default class PixCreationError extends CustomApiError {
  toDictionary() {
    return {
      REPROVED_ACCOUNT: 'Conta de pagamento não encontrada.',
      PIX_KEY_ALREAD_USED: 'Chave Pix já utilizada.',
      PIX_KEY_NOT_ALLOWED_FOR_USER: 'Chave Pix não disponível para usuário.',
      REGISTER_PIX_KEY_LIMIT_EXCEEDED:
        'Limite de chaves Pix cadastradas excedido.',
      AUTH_CODE_NOT_FOUND: 'Código de autenticação não encontrado.',
      EXISTENTE_MESMO_DONO_E_PSP: 'Chave já registrada por outra conta.',
      EXISTENTE_OUTRO_DONO: 'Chave já registrada por outra conta.',
    };
  }
}
