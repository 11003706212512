<template>
  <div>
    <form
      v-if="subSection == 'destination'"
      action="#"
      class="form-group col-12 col-w-full"
      @submit.prevent="sendAuth()"
    >
      <div class="form-group col-12 mb-4">
        <div class="form-group mb-4">
          <div class="d-flow">
            <h4>
              {{ message }}
            </h4>
          </div>
          <div class="row" v-if="!disableMobile">
            <div class="col-3">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  :disabled="disableMobile"
                  :value="true"
                  id="mobile-method"
                  v-model="model.mobile"
                />
                <label class="form-check-label" for="mobile-method">SMS</label>
              </div>
            </div>
            <div class="col-9">
              <span>{{ getMobile }}</span>
            </div>
          </div>
          <div class="row" v-if="!disableEmail">
            <div class="col-3">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  :disabled="disableEmail"
                  :value="false"
                  id="email-method"
                  v-model="model.mobile"
                />
                <label class="form-check-label" for="email-method">Email</label>
              </div>
            </div>
            <div class="col-9">
              <span>{{ getEmail }}</span>
            </div>
          </div>
        </div>
      </div>

      <WhiteLabel class="mb-4 offset-8 col-4 col-w-full">
        <button type="submit" class="btn btn-md btn-orange col-12">
          Continuar
        </button>
      </WhiteLabel>
    </form>

    <form
      v-if="subSection == 'code'"
      action="#"
      class="form-group col-12 col-w-full"
      @submit.prevent="codeReady()"
    >
      <div class="form-group col-12 col-lg-9 mb-4">
        <input
          v-model="authCode"
          required
          type="text"
          class="form-control"
          placeholder="Código de verificação"
          v-mask="['######']"
        />
        <small class="text-danger" v-if="!$v.authCode.minLength">
          O código deve ter 6 caracteres
        </small>
      </div>

      <p v-if="authCodeTimer > 0" class="col-12 col-w-full">
        Você poderá enviar um novo código em
        <b>{{ authCodeTimer }}</b> segundos...
      </p>
      <WhiteLabel class="mb-4">
        <a
          v-if="!(authCodeTimer > 0)"
          href="#"
          class="ml-auto mt-1 mr-4 col-12 col-w-full"
          @click.prevent="sendAuth()"
        >
          Enviar novo código
        </a>
      </WhiteLabel>

      <WhiteLabel class="mb-4 offset-8 col-4 col-w-full">
        <button type="submit" class="btn btn-md btn-orange col-12">
          {{ lastButtonText }}
        </button>
      </WhiteLabel>
    </form>
  </div>
</template>

<script>
// component imports
import WhiteLabel from '@/components/shared/WhiteLabel';

// api imports
import AuthCodeApi from '@/services/v2/AuthCodeApi';

// validation imports
import { minLength } from 'vuelidate/lib/validators';

// models imports
import AuthCodeError from '@/errors/AuthCodeError';

export default {
  name: 'AuthCodeModalSection',

  components: {
    WhiteLabel,
  },

  props: {
    message: {
      type: String,
      required: false,
      default:
        'Selecione um método para recebimento. Este código expirará em 10 minutos',
    },
    subSection: {
      type: String,
      required: true,
      default: 'destination',
    },
    mobile: {
      type: String,
      required: false,
      default: '',
    },
    email: {
      type: String,
      required: false,
      default: '',
    },
    disableMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableEmail: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'pix-payment-order',
    },
    destination: {
      type: String,
      required: false,
      default: null,
    },
    lastButtonText: {
      type: String,
      required: false,
      default: 'Continuar',
    },
  },
  data: () => ({
    model: {
      type: '',
      mobile: true,
    },
    authCode: '',
    authCodeTimer: 20,
  }),

  computed: {
    getEmail() {
      if (!this.email) return '';

      var index = this.email.indexOf('@');
      var length = this.email.length;

      return `${this.email.substring(0, 2)}
        ${'*'.repeat(index - 2)}
        ${this.email.substring(index, length)}`;
    },
    getMobile() {
      if (!this.mobile) return '';
      return `${'*'.repeat(11)}${this.mobile.substring(10, 15)}`;
    },
  },

  watch: {
    authCodeTimer: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.authCodeTimer--;
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    disableMobile: {
      handler(newValue) {
        if (newValue || this.disableEmail)
          this.model.mobile = !this.disableMobile;
      },
    },
    disableEmail: {
      handler(newValue) {
        if (newValue || this.disableMobile)
          this.model.mobile = !this.disableMobile;
      },
    },
  },

  methods: {
    async sendAuth() {
      this.$bus.$emit('spinner-run');

      const api = new AuthCodeApi();

      this.model.type = this.type;
      this.model.destination = this.destination;
      const result = await api.getAuthCode(this.model);

      this.$bus.$emit('spinner-stop');

      if (result.status == 204) {
        this.$emit('auth-sent', this.model.mobile);
        this.authCodeTimer = 30;
      } else if (result.status == 422) {
        const authCodeError = new AuthCodeError(result.data);
        this.$alert.error('Ops', authCodeError.getMessage());
      }
    },

    codeReady() {
      if (this.$v.$invalid) {
        this.$alert.error('Ops', 'Confira o código!');
        return;
      }

      this.$emit('code-ready', this.authCode);
    },

    reset() {
      this.authCode = '';
      this.authCodeTimer = 0;
    },
  },

  validations: {
    authCode: {
      minLength: minLength(6),
    },
  },
};
</script>
